
window.addEventListener('DOMContentLoaded', () => {
  const form = document.getElementById('login-form')
  const loading = document.querySelector('.js__loading')

  const inertAll = () => {
    document.body.inert = true;
  }

  const showLoading = () => {
    loading.classList.add('is-active')
  }
  form.addEventListener('submit', (e) => {
    inertAll()
    showLoading()
  })
})
